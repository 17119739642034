'use client'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

// Define interfaces for the state and action payloads

export interface Product {
  id: number
  name: string
  checked: boolean
}

export interface Variety {
  id: number
  name: string
}

export interface CompanyFilterState {
  searchTerm: string
  sector: string
  fvAllProducts: Product[]
  companySelectedProducts: Product[]
  fvVariety: Variety[]
  fvSelectedActivity: string[]
  companySelectedCompanyLocation: string[]
  ppfLanguage: string
  pphAllproducts: Product[]
  pphSelectedProducts: Product[]
  ppfSelectedRegion: string[]
  ppfSelectedFamily: string[]
  tlSelectedProducts: Product[]
  tlAllProducts: Product[]
  tlSelectedCompanyLocation: string[]
  tlLanguage: string
  fvLanguage: string
  pageNo: number
  order: string
  companySelectedSortOption: string
  clearFilter: boolean
}

// Define initial state

const initialState: CompanyFilterState = {
  searchTerm: '',
  sector: 'Fruit & Vegetable',
  fvSelectedActivity: [],
  fvAllProducts: [],
  companySelectedProducts: [],
  fvVariety: [],
  companySelectedCompanyLocation: [],
  fvLanguage: 'English',
  pphAllproducts: [],
  pphSelectedProducts: [],
  ppfSelectedFamily: [],
  ppfSelectedRegion: [],
  ppfLanguage: 'English',
  tlAllProducts: [],
  tlSelectedProducts: [],
  tlSelectedCompanyLocation: [],
  pageNo: 1,
  order: 'desc',
  companySelectedSortOption: 'Latest Company',
  tlLanguage: 'English',
  clearFilter: false,
}

export const companyFvStaticSupplier = [
  'Grower',
  'Greenhouses Grower',
  'Cooperative',
  'Exporter',
  'Shipper',
  'Broker',
  'Packer',
  'Ripening room',
  'SK Logistics',
]
export const companyFvStaticBuyer = [
  'Buying central',
  'Importer',
  'Wholesaler/Distributor',
  'Hypermarket',
  'Catering',
  'Processor',
  'Supermarket',
  'Secondary wholesaler',
]

// Define slice

export const companyFilterSlice = createSlice({
  name: 'companyFilter',
  initialState,
  reducers: {
    setCompanyOrder: (state, action: PayloadAction<string>) => {
      state.order = action.payload
    },
    setCompanyPageNo: (state, action: PayloadAction<number>) => {
      state.pageNo = action.payload
    },
    setCompanySearchTerm: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload
    },
    setCompanySector: (state, action: PayloadAction<string>) => {
      state.sector = action.payload
    },

    setCompanySelectAllProducts: (state, action: PayloadAction<Product[]>) => {
      if (action.payload.length !== state.companySelectedProducts.length) {
        state.companySelectedProducts = action.payload
      } else if (
        state.companySelectedProducts.length === action.payload.length
      ) {
        state.companySelectedProducts = []
      }
    },

    setCompanyFvAllProducts: (state, action: PayloadAction<Product[]>) => {
      state.fvAllProducts = action.payload
    },

    addOrRemoveCompanySelectedProducts: (
      state,
      action: PayloadAction<Product>
    ) => {
      if (
        state.companySelectedProducts.some((p) => p.id === action.payload.id)
      ) {
        state.companySelectedProducts = state.companySelectedProducts.filter(
          (p) => p.id !== action.payload.id
        )
      } else {
        state.companySelectedProducts = [
          ...state.companySelectedProducts,
          action.payload,
        ]
      }
    },
    addOrRemovePphSelectedProduct: (state, action: PayloadAction<Product>) => {
      if (state.pphSelectedProducts.some((p) => p.id === action.payload.id)) {
        state.pphSelectedProducts = state.pphSelectedProducts.filter(
          (p) => p.id !== action.payload.id
        )
      } else {
        state.pphSelectedProducts = [
          ...state.pphSelectedProducts,
          action.payload,
        ]
      }
    },

    setPpfAllProducts: (state, action) => {
      state.pphAllproducts = action.payload
    },

    setTlProducts: (state, action) => {
      state.tlAllProducts = action.payload
    },
    addOrRemovetlSelectedProduct: (state, action: PayloadAction<Product>) => {
      if (state.tlSelectedProducts.some((p) => p.id === action.payload.id)) {
        state.tlSelectedProducts = state.tlSelectedProducts.filter(
          (p) => p.id !== action.payload.id
        )
      } else {
        state.tlSelectedProducts = [...state.tlSelectedProducts, action.payload]
      }
    },

    setcompanyFvVariety: (state, action) => {
      state.fvVariety = action.payload
    },

    addOrRemoveFvSelectedCompanyLocation: (
      state,
      action: PayloadAction<string>
    ) => {
      if (!state.companySelectedCompanyLocation.includes(action.payload)) {
        state.companySelectedCompanyLocation = [
          ...state.companySelectedCompanyLocation,
          action.payload,
        ]
      } else {
        state.companySelectedCompanyLocation =
          state.companySelectedCompanyLocation.filter(
            (country) => country !== action.payload
          )
      }
    },
    addOrRemovePpfSelectedRegion: (state, action: PayloadAction<string>) => {
      if (!state.ppfSelectedRegion.includes(action.payload)) {
        state.ppfSelectedRegion = [...state.ppfSelectedRegion, action.payload]
      } else {
        state.ppfSelectedRegion = state.ppfSelectedRegion.filter(
          (region) => region !== action.payload
        )
      }
    },

    addOrRemoveCompanySelectedActivity: (
      state,
      action: PayloadAction<string>
    ) => {
      if (!state.fvSelectedActivity.includes(action.payload)) {
        state.fvSelectedActivity = [...state.fvSelectedActivity, action.payload]
      } else {
        state.fvSelectedActivity = state.fvSelectedActivity.filter(
          (region) => region !== action.payload
        )
      }
    },

    setSelectAllCompanyBuyers: (state) => {
      const activitySet = new Set(state.fvSelectedActivity)
      if (companyFvStaticBuyer.every((buyer) => activitySet.has(buyer))) {
        const newCompanyActivity = state.fvSelectedActivity.filter(
          (activity) => !companyFvStaticBuyer.includes(activity)
        )
        state.fvSelectedActivity = newCompanyActivity
      } else {
        const buyerList: string[] = companyFvStaticBuyer.filter(
          (buyer) => !state.fvSelectedActivity.includes(buyer)
        )

        state.fvSelectedActivity = [...state.fvSelectedActivity, ...buyerList]
      }
    },
    setSelectAllCompanySuppliers: (state) => {
      const activitySet = new Set(state.fvSelectedActivity)
      if (
        companyFvStaticSupplier.every((supplier) => activitySet.has(supplier))
      ) {
        const newFruitVegetableActivity = state.fvSelectedActivity.filter(
          (activity) => !companyFvStaticSupplier.includes(activity)
        )
        state.fvSelectedActivity = newFruitVegetableActivity
      } else {
        const supplierList: string[] = companyFvStaticSupplier.filter(
          (supplier) => !state.fvSelectedActivity.includes(supplier)
        )

        state.fvSelectedActivity = [
          ...state.fvSelectedActivity,
          ...supplierList,
        ]
      }
    },

    addOrRemoveTlSelectedLocation: (state, action: PayloadAction<string>) => {
      if (state.tlSelectedCompanyLocation.includes(action.payload)) {
        state.tlSelectedCompanyLocation =
          state.tlSelectedCompanyLocation.filter(
            (item) => item !== action.payload
          )
      } else {
        state.tlSelectedCompanyLocation = [
          ...state.tlSelectedCompanyLocation,
          action.payload,
        ]
      }
    },
    addOrRemovePpfCompanySelectedFamily: (
      state,
      action: PayloadAction<string>
    ) => {
      if (state.ppfSelectedFamily.includes(action.payload)) {
        state.ppfSelectedFamily = state.ppfSelectedFamily.filter(
          (ppfFamily) => ppfFamily != action.payload
        )
      } else {
        state.ppfSelectedFamily = [...state.ppfSelectedFamily, action.payload]
      }
    },

    setFvLanguage: (state, action: PayloadAction<string>) => {
      state.fvLanguage = action.payload
    },
    setPpfLanguage: (state, action: PayloadAction<string>) => {
      state.ppfLanguage = action.payload
    },
    setTlLanguage: (state, action: PayloadAction<string>) => {
      state.tlLanguage = action.payload
    },

    setCompanySelectedOption: (state, action) => {
      state.companySelectedSortOption = action.payload
    },
    setCompanyFiltersToInitialState: (state) => {
      state.clearFilter = !state.clearFilter
      ;(state.companySelectedSortOption = 'Latest Company'),
        (state.sector = 'Fruit & Vegetable'),
        (state.fvSelectedActivity = []),
        (state.companySelectedProducts = []),
        (state.companySelectedCompanyLocation = []),
        (state.fvLanguage = 'English'),
        (state.pphSelectedProducts = []),
        (state.ppfSelectedFamily = []),
        (state.ppfSelectedRegion = []),
        (state.ppfLanguage = 'English'),
        (state.tlSelectedProducts = []),
        (state.tlSelectedCompanyLocation = []),
        (state.pageNo = 1),
        (state.order = 'desc'),
        (state.tlLanguage = 'English')
    },
  },
})

// Export actions and reducer

export const {
  setCompanyOrder,
  setCompanyPageNo,
  setCompanySelectAllProducts,
  setCompanySearchTerm,
  setCompanySector,
  setFvLanguage,
  addOrRemoveCompanySelectedProducts,
  addOrRemoveFvSelectedCompanyLocation,
  addOrRemoveCompanySelectedActivity,
  addOrRemovePphSelectedProduct,
  addOrRemovePpfSelectedRegion,
  setPpfLanguage,
  addOrRemovetlSelectedProduct,
  addOrRemoveTlSelectedLocation,
  addOrRemovePpfCompanySelectedFamily,
  setTlLanguage,
  setCompanyFiltersToInitialState,
  setSelectAllCompanySuppliers,
  setSelectAllCompanyBuyers,
  setCompanyFvAllProducts,
  setcompanyFvVariety,
  setPpfAllProducts,
  setTlProducts,
  setCompanySelectedOption,
} = companyFilterSlice.actions

export default companyFilterSlice.reducer
